"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalSaveDirective = void 0;
const ng = window.angular;
function LocalSaveDirective() {
    return {
        priority: 0,
        restrict: 'A',
        scope: {
            'localSave': '=',
            'scopeId': '=localSaveScope'
        },
        //@ts-ignore
        link: ($scope, $element, attributes, $vm) => {
            const key = `LocalSaveDirective_${attributes['localSave']}_${$scope['scopeId'] || $scope.$id}`;
            $scope.localSave = JSON.parse(localStorage.getItem(key) || 'null');
            $scope.$watch('localSave', (localSave) => {
                localStorage.setItem(key, JSON.stringify(localSave));
            });
            return;
        }
    };
}
exports.LocalSaveDirective = LocalSaveDirective;
LocalSaveDirective.$inject = [];
exports.default = LocalSaveDirective;
