"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopupDirective = exports.PopupService = void 0;
const ng = window.angular;
const fromEvent_1 = require("rxjs/internal/observable/fromEvent");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
class PopupService {
    constructor() {
    }
}
exports.PopupService = PopupService;
class PopupController {
    constructor() {
        this.show = false;
    }
}
PopupController.$inject = [];
function PopupDirective(PopupService) {
    const defaultOpts = {
        forceShow: false,
        watchForceShow: false,
        toggleClass: "open-submenu",
        bodyToggleClass: "",
        keyCodeEsc: ["Escape"],
        hideOutClick: true,
        hideInsideClick: true,
        overlayFocus: true,
    };
    function handleEventFabric(triggerElement, $element, eventName, scopeId, filterCallback) {
        return (0, fromEvent_1.fromEvent)(triggerElement, eventName, { capture: true }).pipe(
        // skipUntil(timer(50)), // пропускать event открытия popup
        (0, operators_1.filter)((event, index) => {
            const element = $element[0];
            if (window.getComputedStyle(element).display == 'none') {
                return false;
            }
            return filterCallback(event, index);
        }), (0, operators_1.take)(1));
    }
    return {
        restrict: 'A',
        transclude: {
            popupTemplate: "popupTemplate"
        },
        template: "<ng-transclude tabindex='0' ng-transclude-slot=\"popupTemplate\"></ng-transclude>",
        controller: PopupController,
        controllerAs: '$vm',
        scope: {
            'popupDirective': '=',
            'popupDirectiveClose': '&'
        },
        //@ts-ignore
        link: ($scope, $element, attributes, $vm) => {
            const allEventDestroy = new rxjs_1.Subject();
            const opts = ng.copy(defaultOpts);
            const overlay = $element[0].querySelector("ng-transclude");
            const parentOverlay = $element[0].closest('ng-transclude');
            ng.extend(opts, $scope['popupDirective'] || {});
            $vm.show = opts.forceShow;
            if (opts.watchForceShow) {
                $scope.$watch('popupDirective.forceShow', (forceShow) => {
                    $vm.show = forceShow;
                });
            }
            const Close = () => {
                if (attributes.popupDirectiveClose) {
                    $scope.popupDirectiveClose({
                        $vm: $vm,
                        $scope: $scope
                    });
                }
                if (opts.bodyToggleClass) {
                    document.body.classList.remove(opts.bodyToggleClass);
                }
                parentOverlay === null || parentOverlay === void 0 ? void 0 : parentOverlay.focus();
            };
            allEventDestroy.subscribe(($element) => {
                // console.error('allEventDestroy', $element);
                Close();
            });
            $scope.$watch('$vm.show', (show, _show) => {
                if (!show) {
                    if (show == _show) {
                        return;
                    }
                }
                show ? $element.addClass(opts.toggleClass) : $element.removeClass(opts.toggleClass);
                if (show) {
                    if ((window.getComputedStyle($element[0]).display != 'none') && opts.bodyToggleClass) {
                        document.body.classList.add(opts.bodyToggleClass);
                    }
                    if (opts.overlayFocus)
                        overlay === null || overlay === void 0 ? void 0 : overlay.focus();
                    if (opts.keyCodeEsc.length && overlay) {
                        handleEventFabric(overlay, $element, 'keydown', $scope.$id, (event, index) => {
                            if (event.target != overlay) {
                                if (event.target.closest('ng-transclude') != overlay) {
                                    return false;
                                }
                            }
                            return opts.keyCodeEsc.indexOf(event.key) >= 0;
                        }).pipe((0, operators_1.takeUntil)(allEventDestroy)).subscribe((event) => {
                            // console.log('close popup by key event rx', event, $element);
                            $scope.$apply(() => {
                                $vm.show = false;
                            });
                        });
                    }
                    if (opts.hideOutClick || opts.hideInsideClick) {
                        handleEventFabric(document.body, $element, 'click', $scope.$id, (event, index) => {
                            console.debug(event, $element[0], opts);
                            if (opts.hideOutClick) {
                                if (event.target.attributes["ngf-select"]) {
                                    return false;
                                }
                                if (!$element[0].contains(event.target)) {
                                    const path = event.path || (event.composedPath && event.composedPath());
                                    const isChild = path.filter((e, index) => {
                                        if (index > 0) {
                                            return e == $element[0];
                                        }
                                    }).length;
                                    return !isChild;
                                }
                            }
                            if (opts.hideInsideClick) {
                                let checkTag = true;
                                if (ng.isArray(opts.hideInsideClick)) {
                                    checkTag = opts.hideInsideClick.indexOf(event.target.nodeName) >= 0;
                                }
                                if ($element[0].contains(event.target) && checkTag) {
                                    return true;
                                }
                            }
                            return false;
                        }).pipe((0, operators_1.takeUntil)(allEventDestroy)).subscribe((event) => {
                            // console.log('close popup by click event rx', event, $element);
                            $scope.$apply(() => {
                                $vm.show = false;
                            });
                        });
                    }
                }
                else {
                    // console.log(show, $element)
                    allEventDestroy.next($element);
                }
            });
            $scope.$on("$destroy", () => {
                // console.log('popup destroy')
                allEventDestroy.next(null);
                allEventDestroy.complete();
            });
        }
    };
}
exports.PopupDirective = PopupDirective;
PopupDirective.$inject = ['PopupService'];
exports.default = PopupDirective;
