"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitFilter = void 0;
const ng = window.angular;
function SplitFilter() {
    return function (value, delimiter, limit) {
        return value.split(delimiter, limit);
    };
}
exports.SplitFilter = SplitFilter;
exports.default = SplitFilter;
