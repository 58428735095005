"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexFilter = void 0;
const momoize_1 = require("@src/shared/momoize");
const ng = window.angular;
function IndexFilter() {
    const reIndex = function (a) {
        if (ng.isArray(a)) {
            return a.map((item, index) => {
                return {
                    originalIndex: index,
                    value: item
                };
            });
        }
        return a;
    };
    return (0, momoize_1.memoize)(reIndex, (a) => {
        if (ng.isArray(a)) {
            return a.join('-');
        }
        return a;
    });
}
exports.IndexFilter = IndexFilter;
exports.default = IndexFilter;
