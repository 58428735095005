"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectDirective = void 0;
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SelectController {
    constructor($scope, $element) {
        this.$scope = $scope;
        this.$element = $element;
        this.autoComplete = '';
    }
    $postLink() {
        const inputElem = this.$element[0].querySelector(`.select__real-model`);
        if (inputElem) {
            this.$modelCtrl = ng.element(inputElem).controller('ngModel');
        }
    }
    onSelect(item) {
        this.autoComplete = '';
        if (this.$modelCtrl) {
            const searchElement = this.$element[0].querySelector('.select__search');
            if (searchElement) {
                setTimeout(() => searchElement.blur());
            }
            this.$modelCtrl.$setViewValue(item);
            this.$modelCtrl.$render();
        }
        else {
            console.error("Missing selectModel model controller");
        }
    }
}
SelectController.$inject = ['$scope', '$element'];
function SelectDirective($timeout) {
    return {
        restrict: 'A',
        transclude: {
            selectTemplate: "selectTemplate"
        },
        template: ($element, $attrs) => {
            if ($attrs.selectInlineTemplate) {
                return "<ng-transclude ng-transclude-slot=\"selectTemplate\"></ng-transclude>";
            }
            else {
                return require("./select.ng.html");
            }
        },
        controller: SelectController,
        controllerAs: '$vm',
        scope: {
            'selectDirective': '=',
            'selectModel': '=',
            'selectItems': '=',
            'selectPlaceholder': '@',
            'selectRequired': '=',
        },
        //@ts-ignore
        link: ($scope, $element, attributes, $vm) => {
            const allEventDestroy = new rxjs_1.Subject();
            const searchElement = $element[0].querySelector('.select__search');
            let previousActiveElement = null;
            $timeout(() => {
                previousActiveElement = document.activeElement;
            }, 100);
            if (searchElement) {
                searchElement.addEventListener('focus', () => {
                    $timeout(() => {
                        $scope.showSelect = true;
                    });
                });
                searchElement.addEventListener('blur', (event) => {
                    $timeout(() => {
                        if (document.activeElement != event.target) {
                            $scope.showSelect = false;
                        }
                    });
                });
            }
            $scope.toggleFocus = function ($event) {
                $event.preventDefault();
                if (searchElement) {
                    $scope.showSelect ? searchElement.blur() : searchElement.focus();
                }
            };
            $scope.showSelect = false;
            (0, rxjs_1.fromEvent)($element[0], 'keydown').pipe((0, operators_1.filter)((event) => {
                if (event.key == 'Enter') {
                    const matches = $scope.selectItems.filter((item) => {
                        return item.label == $vm.autoComplete;
                    });
                    if (matches) {
                        $vm.onSelect(matches[0]);
                        return true;
                    }
                }
                else {
                    return ["Escape"].indexOf(event.key) >= 0 && $scope.showSelect;
                }
            }), (0, operators_1.takeUntil)(allEventDestroy)).subscribe(() => {
                if (searchElement) {
                    searchElement.blur();
                }
                if (!$vm.$modelCtrl.$modelValue) {
                    $vm.autoComplete = '';
                }
            });
            $scope.$watch('$vm.autoComplete', () => {
                if ($vm.autoComplete && !$scope.showSelect) {
                    $scope.showSelect = true;
                }
            });
            $scope.$on("$destroy", () => {
                allEventDestroy.next(null);
                allEventDestroy.complete();
            });
        }
    };
}
exports.SelectDirective = SelectDirective;
SelectDirective.$inject = ['$timeout'];
exports.default = SelectDirective;
