"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinFilter = void 0;
const ng = window.angular;
function JoinFilter() {
    return function (value, delimiter) {
        return (value || []).join(delimiter != undefined ? delimiter : ',');
    };
}
exports.JoinFilter = JoinFilter;
exports.default = JoinFilter;
