"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleFilter = void 0;
const ng = window.angular;
function TitleFilter() {
    return function (value) {
        if (value != undefined) {
            const stringValue = value.toString();
            if (stringValue.length >= 2) {
                //@ts-ignore
                return `${stringValue[0].toUpperCase()}${stringValue.slice(1, stringValue.length)}`;
            }
            return stringValue.toUpperCase();
        }
        return value;
    };
}
exports.TitleFilter = TitleFilter;
exports.default = TitleFilter;
