"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.soundServiceProvider = exports.SoundService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const SoundBox = require('sound-box').default;
class SoundService {
    constructor($q, ConfigService, soundMap) {
        this.$q = $q;
        this.ConfigService = ConfigService;
        this.soundMap = soundMap;
        this.default_volume = 0.75;
        this.playOnce$ = new rxjs_1.Subject();
        this.soundIsLoaded = false;
        this.soundbox = new SoundBox();
        this.duration$ = new rxjs_1.Subject();
        this.killDuration$ = new rxjs_1.Subject();
        if (this.ConfigService.cookieSettings.sound_effects) {
            this.loadSound(this.soundMap);
        }
        this.duration$.pipe((0, operators_1.filter)(() => {
            return this.default_duration != undefined;
        }), (0, operators_1.switchMap)((duration) => {
            return (0, rxjs_1.interval)(1000).pipe((0, operators_1.tap)((i) => {
                if (i == ((duration / 1000) - 2)) {
                    const soundInstances = this.soundbox.instances;
                    if (soundInstances.length) {
                        soundInstances[soundInstances.length - 1].volume = soundInstances[soundInstances.length - 1].volume / 2;
                        console.log(soundInstances[soundInstances.length - 1].volume);
                    }
                }
                if (i == ((duration / 1000) - 1)) {
                    const soundInstances = this.soundbox.instances;
                    if (soundInstances.length) {
                        soundInstances[soundInstances.length - 1].volume = soundInstances[soundInstances.length - 1].volume / 3;
                        console.log(soundInstances[soundInstances.length - 1].volume);
                    }
                }
            }), (0, operators_1.finalize)(() => {
                console.log('stop all by duration');
                this.soundbox.stop_all();
            }), (0, operators_1.takeUntil)((0, rxjs_1.timer)(duration + 1000)));
        })).subscribe();
        this.soundbox.default_volume = this.default_volume;
        this.playOnce$.pipe((0, operators_1.pairwise)(), (0, operators_1.filter)(([previousValue, currentValue]) => {
            return previousValue != currentValue;
        }), (0, operators_1.tap)(([previousValue, currentValue]) => {
            if (currentValue)
                this.play(currentValue);
        })).subscribe();
    }
    loadSound(soundMap) {
        return __awaiter(this, void 0, void 0, function* () {
            const promises = [];
            for (const [key, value] of Object.entries(soundMap)) {
                promises.push(this.soundbox.load(key, value).then(() => console.debug(`Loaded ${key}`), (e) => {
                    console.log(e, key, this.soundMap);
                    delete this.soundMap[key];
                    console.error(`Failed to load ${value}`);
                }));
            }
            return this.$q.all(promises).then(() => {
                this.soundIsLoaded = true;
            });
        });
    }
    play(id, loop = false, volume, callback) {
        console.debug(id);
        if (this.ConfigService.cookieSettings.sound_effects) {
            if (!this.soundIsLoaded) {
                return this.loadSound(this.soundMap).then(() => {
                    this.duration$.next(this.default_duration);
                    return this.soundbox.play(id, callback, volume, loop).finally(() => {
                        this.killDuration$.next();
                    });
                });
            }
            else {
                this.duration$.next(this.default_duration);
                let p = new Promise((resolve, reject) => {
                    if (!callback) {
                        this.soundbox.play(id, callback, volume, loop).then(() => {
                            resolve(true);
                        }, (err) => reject(err));
                    }
                    else {
                        this.soundbox.play(id, () => {
                            callback().then(() => {
                                resolve(true);
                            }, (err) => {
                                reject(err);
                            });
                        }, volume, loop);
                    }
                });
                p.then(() => {
                    this.killDuration$.next();
                });
                return p;
            }
        }
        return this.$q.when(null);
    }
    playDuration(duration, id, loop = false, volume, callback) {
        if (this.ConfigService.cookieSettings.sound_effects) {
            if (!this.soundIsLoaded) {
                return this.loadSound(this.soundMap).then(() => {
                    this.duration$.next(duration);
                    return this.soundbox.play(id, callback, volume, loop).finally(() => {
                        this.killDuration$.next();
                    });
                });
            }
            else {
                this.duration$.next(duration);
                return this.soundbox.play(id, callback, volume, loop).finally(() => {
                    this.killDuration$.next();
                });
            }
        }
        return this.$q.when(null);
    }
    playOnce(id) {
        this.playOnce$.next(id);
    }
    stop_all() {
        this.soundbox.stop_all();
        if (this.default_duration) {
            this.killDuration$.next();
        }
    }
    getOptions() {
        const result = [];
        for (const [key, value] of Object.entries(this.soundMap)) {
            if (key[0] != '~')
                result.push({
                    label: key,
                    value: value
                });
        }
        return result;
    }
}
exports.SoundService = SoundService;
exports.default = SoundService;
function soundServiceProvider() {
    let soundMap = {};
    this.setSound = (config) => {
        soundMap = config;
    };
    this.$get = ['$q', 'ConfigService', ($q, ConfigService) => {
            return new SoundService($q, ConfigService, soundMap);
        }];
    return this;
}
exports.soundServiceProvider = soundServiceProvider;
