"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wsServiceProvider = void 0;
function wsServiceProvider() {
    let prefix = '';
    this.setPrefix = (newPrefix) => {
        prefix = newPrefix;
    };
    this.$get = ['$cookies', ($cookies) => {
            // return new WsService($cookies, prefix);
        }];
    return this;
}
exports.wsServiceProvider = wsServiceProvider;
