"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toArrayFilter = void 0;
const ng = window.angular;
function toArrayFilter($sce) {
    return function (obj, addKey) {
        if (!ng.isObject(obj))
            return obj;
        if (addKey === false) {
            return Object.keys(obj).map(function (key) {
                return obj[key];
            });
        }
        else {
            return Object.keys(obj).map(function (key) {
                var value = obj[key];
                return ng.isObject(value) ?
                    Object.defineProperty(value, '$key', { enumerable: false, value: key }) :
                    { $key: key, $value: value };
            });
        }
    };
}
exports.toArrayFilter = toArrayFilter;
toArrayFilter.$inject = [];
exports.default = toArrayFilter;
