"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyNumberFilter = void 0;
const ng = window.angular;
function MyNumberFilter($filter) {
    return function (value, fractionSize) {
        if (value == undefined) {
            return '';
        }
        if (ng.isString(value)) {
            value = Number(value);
        }
        console.debug($filter('number')(5.971474565516303, fractionSize), value);
        if (parseInt(value.toString()) == parseFloat(value.toFixed(fractionSize))) {
            return $filter('number')(value, 0);
        }
        return $filter('number')(value, fractionSize).replace(/(?<=([\.\,]+[0-9]*))0+$/, '')
            .replace(/[\,\.]$/, '');
    };
}
exports.MyNumberFilter = MyNumberFilter;
MyNumberFilter.$inject = ['$filter'];
exports.default = MyNumberFilter;
